import { Menu } from '@headlessui/react'
import {
  sendBirdSelectors,
  useSendbirdStateContext,
} from '@sendbird/uikit-react'
import Channel from '@sendbird/uikit-react/Channel'
import { useEffect, useRef, useState } from 'react'
import { AiOutlineSend } from 'react-icons/ai'
import { HiDotsVertical } from 'react-icons/hi'
import {
  MdArchive,
  MdLink,
  MdOutlineAddIcCall,
  MdOutlineArrowCircleLeft,
  MdUnarchive,
} from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import SendBirdCall from 'sendbird-calls'
import '../../../customStyle/custom-sendbird.css'
import { DoctorDetailsRequest } from '../../../redux/actions/authActions'
import { toastError, toastModal } from '../../../utils/helpers/methods'
import ForwardMessageModal from '../../ForwardMessageModal'
import ChatCall from '../calls'
import Modal from '../modal'
import { Badge } from "flowbite-react";
import {
  PatientListfilter,
  getPatientList,
} from '../../../redux/actions/passActions'
import Spinner from '../spinner'
import UpdateMessage from '../../UpdateMessage'
import {
  saveCallDuration,
  SaveChannelMessage,
  saveChatAssign,
  UpdateChannelLatestMessage,
} from '../../../redux/actions/chat'
import { CustomChat } from './customChat'
import { getFileIcon } from '../input/fileUtils'
import { FaFileImport } from 'react-icons/fa'
import { toogleUplaod } from '../../../redux/slices/chat'
import { IoMdAttach, IoMdCloseCircle} from 'react-icons/io'
import { SENDBIRD_INFO } from '../../../utils/helpers/constant'
import {
  addCall,
  updateCall,
  updateCallDuration,
  deleteAll,
} from '../../../redux/slices/chatHistory'
import { GetPatientTag } from '../../../utils/services/patient'
import { Icon } from '@iconify/react'
import removeDataOnAssignedChat from '../../../utils/services/archive'

const ChatUI = ({
  activeTab,
  channel,
  onSendMessage,
  close,
  currentUser,
  selectedChat,
}) => {
  const store = useSendbirdStateContext()

  const inputRef = useRef('')
  const { userInfo, doctorDetails, userToken } = useSelector(
    (state) => state.auth
  )
  const { open } = useSelector((state) => state.sidebar)
  const token = userInfo.doctor_login.sendbird_user_id
  const dispatch = useDispatch()
  const [showCall, setShowCall] = useState(false)
  const [callInstance, setCallInstance] = useState()
  const [mute, setMute] = useState(false)
  const [connected, setConnected] = useState(false)
  const [showCallIcon, setShowCallIcon] = useState(false)
  const [text, setText] = useState('')
  const [showShortcuts, setshowShortCuts] = useState(false)
  const [shortCuts, setShortcuts] = useState([])
  const [showArchive, setShowArchive] = useState(true)
  const [showUnArchive, setShowUnArchive] = useState(false)
  const [user, setUser] = useState('')
  const [message, setMessage] = useState('')
  const [messageForwardedFrom, setMessageForwardedFrom] = useState('')
  const [patientList, setPatientList] = useState([])
  const [forwardId, setForwardId] = useState('')
  const [messageId, setMessageId] = useState('')
  const [isTeam, setIsTeam] = useState('')
  const { PatientList, PatientListinfo } = useSelector((state) => state.pass)
  const [selectedShortCut,setSelectedShortCut] = useState()
  const [selectedShortCutFiles, setSelectedShortCutFiles] = useState([])
  const [selectedShortCutLinks, setSelectedShortCutLinks] = useState([])
  const [patientStatusInfo, setPatientStatusInfo] = useState()

  const handleGetDoctorDetails = () => {
    dispatch(DoctorDetailsRequest({ id: userInfo?.id }))
  }
  const getpatientStatusInfo=async ()=>{
    const filteredPA = channel.members.filter((x) => x.userId.slice(0, 2) === 'PA');
    const id= filteredPA[0].userId.slice(2);
    await GetPatientTag(id).then((response)=>{
    setPatientStatusInfo(response?.data?.data)
  });
  }
  useEffect(() => {
      if(channel.customType=='DPCDoctor'){
        getpatientStatusInfo();
      }
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: '',
      patient_tag: '',
      search_key: '',
      limit: 5,
      patient_login: '1',
    }
    dispatch(getPatientList(obj))
  }, [])

  useEffect(() => {
    if (PatientList) {
      let newList = PatientList.filter((item) => item.patient_login !== null)
      setPatientList(newList)
    }
  }, [PatientList])

  //   useEffect(()=>{
  //  dispatch(deleteAll())
  //   },[])

  useEffect(() => {
    const pa = channel.members.find((item) => item.userId.slice(0, 2) === 'PA')
    if (channel.isHidden) {
      setShowArchive(false)
      setShowUnArchive(true)
    } else {
      setShowArchive(true)
      setShowUnArchive(false)
    }
    if (pa && pa?.userId) {
      setShowCallIcon(true)
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentUser) {
      setUser(currentUser?.userInfo?.doctor_login?.sendbird_user_id)
    }
  }, [currentUser])

  useEffect(() => {
    handleGetDoctorDetails()
    //eslint-disable-next-line
  }, [])

  const archiveChat = async () => {
    if(activeTab=='tab1' || activeTab=='tab0'){
      removeDataOnAssignedChat(channel,()=>{
       setShowArchive(false)
       setShowUnArchive(true)
       toastModal('Archived Successfully');
     }); //on archiving from assigned chat  need to remove assigned label for all
    }
    else{
    var hideParams = {
      hidePreviousMessages: false,
      allowAutoUnhide: true,
    }
    await channel.hide(hideParams).then((res) => {
      try {
        setShowArchive(false)
        setShowUnArchive(true)
        toastModal('Archived Successfully');
      } catch (error) {
        toastError(error)
      }
    })
  }
  }

  const unArchiveChat = async () => {
    await channel.unhide().then((res) => {
      try {
        setShowArchive(true)
        setShowUnArchive(false)
        toastModal('UnArchived Successfully')
      } catch (error) {
        toastError(error)
      }
    })
  }

  const initializeCall = async (isVideoCall) => {
    SendBirdCall.authenticate({ userId: token }, (result, error) => {
      if (error) {
      } else {
        /* Succeeded to connect*/
        const callerId = token
        setShowCall(true)
        if (callerId && showCallIcon) {
          const calleeId = channel.members.find(
            (item) => item.userId.slice(0, 2) === 'PA'
          )
          const dialParams = {
            userId: calleeId?.userId,
            isVideoCall: true,
            callOption: {
              localMediaView: document.getElementById('local_video_element_id'),
              remoteMediaView: document.getElementById(
                'remote_video_element_id'
              ),
              audioEnabled: true,
              videoEnabled: true,
            },
            sendBirdChatOptions: {
              channel,
            },
          }
          const call = SendBirdCall.dial(dialParams, (call, error) => {
            if (error) {
              // Setting media viewers lazily.
            }
            call.setLocalMediaView(
              document.getElementById('local_video_element_id')
            )
            call.setRemoteMediaView(
              document.getElementById('remote_video_element_id')
            )
          })

          setCallInstance(call)

          call.onFailed = (Call) => {
            toastError('Call Failed')
            setShowCall(false)
            setConnected(false)
          }
          call.onEstablished = (call) => {}
          call.onConnected = (call) => {
            setShowCall(true)
            setConnected(true)
          }
          call.onEnded = (callEndResult) => {
            console.log('ce->',{callEndResult})
            setShowCall(false)
            setConnected(false)
            const sendUserMessage = sendBirdSelectors.getSendUserMessage(store)
            const obj = {
              duration: callEndResult.callLog.duration,
              time: callEndResult.callLog?.startedAt,
              callStatus: (callEndResult.endResult).toUpperCase(),
            }
            const endTime = callEndResult._startedAt + call.getDuration()
            const durationMillis = call.getDuration()
            const durationSeconds = Math.round(durationMillis / 1000)

            const hours = Math.floor(durationSeconds / 3600)
            const minutes = Math.floor((durationSeconds % 3600) / 60)
            const seconds = durationSeconds % 60
            const duration = `${hours > 0 ? `${hours}h ` : ''}${
              minutes > 0 ? `${minutes}m ` : ''
            }${seconds > 0 ? `${seconds}s` : ''}`

            const data = {
              sender_id: parseInt(callerId.replace('PR','')),
              sender_type: 'doctor',
              receiver_id: parseInt(calleeId.userId.replace("PA",'')),
              receiver_type: 'patient',
              caller_id: callEndResult.callId,
              start_time: new Date(callEndResult._startedAt),
              end_time: new Date(endTime),
              call_status: `${callEndResult.endResult}`,
              duration,
              channel_id:channel.id
            }
            dispatch(saveCallDuration({ data, success: () => ({}) }))
            sendUserMessage(channel, {
              message: JSON.stringify(obj),
              customType: 'VIDEO_CALL',
            }).then(() => {
              toastError('Call Ended')
            })
          }

          call.onReconnecting = (call) => {}
          call.onRemoteAudioSettingsChanged = (call) => {
            //...
          }
          call.onRemoteVideoSettingsChanged = (call) => {
            //...
          }

          // const directCall = await SendBirdCall.getDirectCall(callProps.callId);
          // navigate(isVideoCall ? 'chat-call' : 'chat-call', {state:{ callId: callProps.callId, directCall, nickname: channel.members.find((item)=>item.userId.startsWith("PR")).nickname }});
        }
      }
    }).catch((error) => {
      toastError('')
    })
  }
  const endCall = () => {
    if (callInstance) {
      callInstance.end()
      setShowCall(false)
    }
  }
  const openCamera = () => {
    if (callInstance) {
      callInstance.startVideo()
    }
  }
  const closeCamera = () => {
    if (callInstance) {
      callInstance.stopVideo()
    }
  }
  const muteMicrophone = () => {
    if (callInstance) {
      setMute(!mute)
      if (mute) {
        return callInstance.unmuteMicrophone()
      }
      return callInstance.muteMicrophone()
    }
  }

  const submitMessage = () => {
    let message = {
      file: selectedShortCutFiles,
      url: selectedShortCutLinks,
      message: text,
    }
    console.log(message,selectedShortCutFiles)
    const sendUserMessage = sendBirdSelectors.getSendUserMessage(store)
    sendUserMessage(channel, {
      data: JSON.stringify(message),
      message: text,
    })
    // sendUserMessage(channel, {
    //   message: text,
    // })
      // .onSucceeded(() => {
      //   setText('')
      // })
      .onFailed(() => {
        setText('')
        toastError('Send message failed, try again')
      })
      .onSucceeded((message) => {
        setText('');
        setSelectedShortCut(null);
        setSelectedShortCutFiles([])
        setSelectedShortCutLinks([])
        if (selectedChat) {
          var receiverType, receiverUserID
          inputRef.current.value = ''
          if (channel.name == 'Teams') {
            receiverType = 'internal_doctor'
          } else if (channel.name == 'Admin') {
            receiverType = 'admin'
          } else if (channel.name == 'DPCDoctor') {
            receiverType = 'patient'
          }
          receiverUserID = selectedChat.id
        } else if (!selectedChat && channel.name == 'Admin') {
          receiverType = 'admin'
          receiverUserID = 1
        }
        if(message){
          updateLatestMessage(message);
        }
        if(message && receiverType && receiverUserID){
        saveChannelDetails(message, receiverType, receiverUserID)
        }
               
      })
  }
  const updateLatestMessage = (message) => {
    if (message) {
      let data = {
        sendbird_channel_url: message.channelUrl
      }
     dispatch(UpdateChannelLatestMessage({ data: data }))
    }
  }

  const saveChannelDetails = (message, receiverType, receiverUserID) => {
    if (message) {
      let data = {
        sendbird_user_id: message.sender.userId,
        sendbird_channel_url: message.channelUrl,
        user_type:
          userInfo &&
          userInfo.doctor_login &&
          userInfo.doctor_login.user_type &&
          userInfo.doctor_login.user_type.length > 0
            ? userInfo.doctor_login.user_type
            : 'doctor',
        message_id: message.messageId,
        user_id: userInfo?.id,
        receiver_type: receiverType,
        receiver_user_id: receiverUserID,
      }
      dispatch(SaveChannelMessage({ data: data }))
    }
  }
  const deleteMyChat=async(message)=>{
    channel.deleteMessage(message, (response, error) => {
      if (error) {
        console.error('Failed to delete message', error);
      } 
    });
    } 
  const addToMyChat = async (item) => {
    if (
      item.message &&
      item.message.channelUrl &&
      item.message.channelUrl.length > 0
    ) {
      await channel.unhide()
      var hideList = channel.members.filter(
        (x) => x.userId != token && !x.userId.startsWith('PA')
      )
      var dataParams = { data: token }
      fetch(
        `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${item.message.channelUrl}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Api-Token': SENDBIRD_INFO.sendBirdToken,
          },
          body: JSON.stringify(dataParams),
        }
      )
        .then((updatedChannel) => {
          if (updatedChannel != '') {
            Promise.all([
              hideList.map(async (x) => {
                return fetch(
                  `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${item.message.channelUrl}/hide`,
                  {
                    method: 'PUT',
                    headers: {
                      'Content-Type': 'application/json',
                      'Api-Token': SENDBIRD_INFO.sendBirdToken,
                    },
                    body: JSON.stringify({
                      user_id: x.userId,
                      allow_auto_unhide: false,
                    }),
                  }
                )
              }),
            ]).then((res) => {
              let reqdata= {
                "sendbird_channel_url":item.message.channelUrl,
                "assign_user_id":userInfo?.id,
                "assign_user_type":userInfo.doctor_login.user_type ===null?'provider':'nonprovider',
                "site_type":"careappweb"
               }
              dispatch(saveChatAssign({ data: reqdata }))
             

              close()
            })
          } else toastError("Couldn't Move to Assign channel")
        })
        .catch((e) => {
          toastError('Something went wrong')
        })
    }
  }

  const checkForShortcut = (e) => {
    const text = inputRef.current.value
    const reg = /#/
    const check = reg.test(text)
    if (check) {
      const textMessage = text.split('#')[1]
      const data = doctorDetails?.data?.text_shortcuts.filter((word) =>
        word.key_word.toLowerCase().includes(textMessage.toLowerCase())
      )
      setShortcuts(data)
      setshowShortCuts(true)
      setText(text)
    } else {
      setText(text)
      setshowShortCuts(false)
    }
  }
  const handleBack = () => {
    close && close()
  }
  const handleReplace = (shortcut) => {
    setSelectedShortCut(shortcut);
    setSelectedShortCutFiles((prevFiles) => {
      // Filter new items to only include those not already in prevFiles
      const uniqueNewFiles = shortcut?.file_name.filter(
        (file) => !prevFiles.includes(file)
      );
          return [...prevFiles, ...uniqueNewFiles];
    }); 
    setSelectedShortCutLinks((prevLink) => {
      // Filter new items to only include those not already in prevFiles
      const unique = shortcut?.replacement_link.filter(
        (link) => !prevLink.includes(link)
      );
          return [...prevLink, ...unique];
    }); 
    // let message = {
    //   file: shortcut.file_name,
    //   //fileType:shortcut.file_type,
    //   url: shortcut.replacement_link,
    //   message: shortcut.message,
    // }
    // const sendUserMessage = sendBirdSelectors.getSendUserMessage(store)
    // sendUserMessage(channel, {
    //   data: JSON.stringify(message),
    //   message: shortcut.key_word,
    // })
    //   .onSucceeded(() => {
    //     setText('')
    //   })
    //   .onFailed(() => {
    //     setText('')
    //     toastError('Send message failed, try again')
    //   })
    //   .onSucceeded((message) => {
    //     setText('')
    //     if (selectedChat) {
    //       var receiverType, receiverUserID
    //       inputRef.current.value = ''
    //       if (channel.name == 'Teams') {
    //         receiverType = 'internal_doctor'
    //       } else if (channel.name == 'Admin') {
    //         receiverType = 'admin'
    //       } else if (channel.name == 'DPCDoctor') {
    //         receiverType = 'patient'
    //       }
    //       receiverUserID = selectedChat.id
    //     } else if (!selectedChat && channel.name == 'Admin') {
    //       receiverType = 'admin'
    //       receiverUserID = 1
    //     }
    //     saveChannelDetails(message, receiverType, receiverUserID)
    //   })
    let replacementText = `${shortcut.message}`; // Start with the message
    console.log('test-------------',shortcut);
    // if (shortcut.replacement_link && shortcut.replacement_link.length > 0 && shortcut.replacement_link!=null) {
    //     shortcut.replacement_link.forEach((link) => {
    //         replacementText += `\n${link}`;
    //     });
    //     console.log('test-------------',replacementText);

    // }
    // if (shortcut.file_name.length > 0) {
    //      // replacementText += `\n ${doctorDetails?.data?.replacement_url+'/'+shortcut.file_name}`;
    //       shortcut.file_name.forEach((link) => {
    //         replacementText += `\n ${doctorDetails?.data?.replacement_url+'/'+link.filename}`;
    //         //replacementText += `\n${link}`;
    //     });
    //     }
    const newText = text.replace(/#/, replacementText);
    setText(newText);
    inputRef.current.value = newText;
    setshowShortCuts(false)
  }
  const removeShortcutFile=(removeData)=>{
    console.log(removeData);
    const filteredData=selectedShortCutFiles.filter((shortCutData)=>shortCutData.filename!=removeData)
    setSelectedShortCutFiles(filteredData) 
  }
  const removeShortcutLink=(removeData)=>{
    console.log(removeData);
    const filteredData=selectedShortCutLinks.filter((shortCutData)=>shortCutData!=removeData)
    setSelectedShortCutLinks(filteredData) 
  }
  const getName = () => {
    if (channel.name === 'Admin') {
      return 'ConnectCare Team'
    } else if (channel.name === 'DPCDoctor') {
      const filtered = channel.members.filter(
        (x) => x.userId.slice(0, 2) === 'PA'
      )
      return filtered?.map((item, index) =>
        filtered.length - 1 === index ? item.nickname : item.nickname + ','
      )
    } else if (channel.name === 'Teams') {
      const filtered = channel.members.filter(
        (x) =>
          x.userId !== currentUser?.userInfo?.doctor_login?.sendbird_user_id
      )
      return filtered?.map((item, index) =>
        filtered.length - 1 === index ? item.nickname : item.nickname + ','
      )
    }
  }

  const handleGetForwarderId = async () => {
    if (channel.name == 'Admin') {
      var forwarder = 'Admin'
      return forwarder
    } else if (channel.name == 'Teams') {
      const forwarderId = channel.members.find(
        (item) => item.userId !== currentUser
      )
      return forwarderId.userId
    } else if (channel.name == 'DPCDoctor') {
      const forwarderId = channel.members.find(
        (item) => item.userId !== currentUser && item.userId.startsWith('PA')
      )
      return forwarderId.userId
    }
  }

  useEffect(() => {
    ;(async () => {
      let id = await handleGetForwarderId()
      setForwardId(id)
    })()
  }, [])

  useEffect(() => {
    if (channel.name == 'Teams' || channel.name == 'Admin') {
      setIsTeam(true)
    }
  }, [channel])

  const formatMessageTime = (timestamp) => {
    const date = new Date(timestamp)
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const ampm = hours >= 12 ? 'PM' : 'AM'
    const formattedHours = hours % 12 || 12
    const formattedTime = `${formattedHours}:${
      minutes < 10 ? '0' : ''
    }${minutes} ${ampm}`
    return formattedTime
  }

  function getUpdateInfo(id, message) {
    setMessageId(id)
    setMessage(message)
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      submitMessage()
    }
  }

  function uploadFile(e) {
    e.preventDefault()
    dispatch(toogleUplaod())
  }

  const filteredChannels =
    channel?.name !== 'DPCDoctor' &&
    channel?.members
      ?.filter(
        (item) =>
          item.userId !== currentUser?.userInfo?.doctor_login?.sendbird_user_id
      )
      ?.map((item) => item)

  return (
    <>
      {showCall && (
        <div className='flex justify-center items-center'>
          <ChatCall
            onCallEnd={endCall}
            onStartVideo={openCamera}
            onEndVideo={closeCamera}
            connected={connected}
            mute={mute}
            onMute={muteMicrophone}
          />
        </div>
      )}
      {!showCall && (
        <>
          <div className='sendbird-app__channellist-wrap'>
            {/* <ChannelList /> */}
          </div>
          <div
            className={` ${
              open ? 'w-[100%] ' : 'w-[120%] '
            } flex flex-col absolute  bottom-0 left-0 h-[90vh] overflow-y-auto sendbird-app__conversation-wrap`}
          >
            <Channel
              channelUrl={channel?.url}
              renderMessageInput={() => {
                return (
                  <section className='w-full flex-col overflow-y-auto px-2 h-full'>
                    {showShortcuts && shortCuts.length > 0 && (
                      <div className='w-full h-min py-4 border '>
                        {shortCuts.map((item, index) => (
                          <div
                            className='flex flex-row hover:bg-everglade-400 hover:text-white'
                            onClick={() => handleReplace(item)}
                          >
                            {/* { item?.file_name?.map((e)=>{
                          return e.filename
                        })} */}
                            {item?.file_name?.length > 0 &&
                              item?.file_name?.map((e) => {
                                ;<section
                                  className='w-10 m-5 '
                                  key={index + 'img'}
                                >
                                  {getFileIcon(
                                    e,
                                    doctorDetails?.data?.replacement_url
                                  )}
                                </section>
                              })}
                            <section
                              key={index}
                              className='flex flex-col p-2 cursor-pointer mt-2'
                            >
                              <span className='font-semibold text-xl text-everglade-800'>
                                #{item?.key_word}
                              </span>
                              <span className='text-xs pl-2'>
                                {item?.message}
                              </span>
                              {item?.replacement_link?.length > 0 &&
                                item?.replacement_link?.map((link, index) => (
                                  <span className='text-xs pl-2 font-semibold'>
                                    <span class='text-everglade-900'>
                                      {link}
                                    </span>{' '}
                                  </span>
                                ))}
                            </section>
                          </div>
                        ))}
                      </div>
                    )}

                    <div className='relative flex flex-row items-center flex-wrap px-2 pt-2'>
                      {selectedShortCutFiles?.length>0 &&
                     <div className='flex flex-row'>{
                      selectedShortCutFiles.map((e)=><div className='relative remove-block pb-2'>
                        {getFileIcon(e,doctorDetails?.data?.replacement_url)}
                        {/* <Avatar img={doctorDetails?.data?.replacement_url+'/'+e.filename} className='relative img-block'/> */}
                        <IoMdCloseCircle className='remove-icon' onClick={()=>removeShortcutFile(e.filename)}/>
                        </div>)
                      }
                      </div>}  
                      
                      {selectedShortCutLinks.map((e)=>
                       <div className='relative remove-block pb-2'>
                        <Badge icon={MdLink}>{e}</Badge>
                        <IoMdCloseCircle className='remove-icon' onClick={()=>removeShortcutLink(e)}/>
                        </div>
                        ) }    
                        </div>

                    {(patientStatusInfo?.patients.deleted_at==null||patientStatusInfo?.patients.deleted_at=='')?
                    <div className='border-2 w-full flex flex-row items-center px-2'>
                      <IoMdAttach
                        className=' text-everglade-500 hover:cursor-pointer gap-5 my-2'
                        size={30}
                        onClick={uploadFile}
                      />
                      <textarea
                        ref={inputRef}
                        onChange={checkForShortcut}
                        value={text}
                        placeholder='Enter Message'
                        onKeyDown={handleKeyPress}
                        className=' border-0 outline-0  focus:border-none  border-none flex w-[100%] mx-2'
                      />
                      {text !== '' && (
                        <button
                          className='text-everglade-600 mx-2'
                          onClick={submitMessage}
                        >
                          <AiOutlineSend size={30} />
                        </button>
                      )}
                    </div>
                    :<div className='border-2 w-full text-center  p-2 bg-red-400'>
                    <h1 className='font-extrabold'>Your Coverage is EXPIRED, You cannot chat with a doctor. Please contact the ConnectCare Team.</h1>
                    </div>}
                  </section>
                )
              }}
              renderVoiceMessageIcon={() => <></>}
              renderFileUploadIcon={() => <></>}
              renderMessage={(message) => {
                return (
                  <CustomChat deleteMyChat={deleteMyChat} addToMyChat={addToMyChat} setMessage={setMessage} setMessageForwardedFrom={setMessageForwardedFrom} channel={channel} formatMessageTime={formatMessageTime} user={user} message={message}  />
                )
              }}
              // renderTypingIndicator={(ty)=>{
              //    console.log({ty})
              // }}
              renderChannelHeader={() => (
                <section className='flex items-center justify-between px-10'>
                  <section className='flex items-center'>
                    <MdOutlineArrowCircleLeft
                      onClick={handleBack}
                      className='text-everglade-600 cursor-pointer'
                      size={30}
                    />

                    <p className='p-4 pl-8 font-semibold'>{getName()}</p>
                    <div  className='flex flex-row items-center justify-between'>
                          {patientStatusInfo?.patients?.is_vip==1 && <Icon icon="quill:vip" fontSize={40} color='#ffbf00'></Icon>}
                          {/* {patientStatusInfo?.patients?.is_vip==1 &&  <Badge className="ml-1" color="warning">VIP</Badge>} */}
                          {patientStatusInfo?.patients  &&  <Badge className="ml-1" size={40} color={(patientStatusInfo?.patients.deleted_at==null||patientStatusInfo?.patients.deleted_at=='')?'success':'failure'}>{(patientStatusInfo?.patients.deleted_at==null||patientStatusInfo?.patients.deleted_at=='')?'Active':'InActive'}</Badge>}
                          {patientStatusInfo?.location_group?.group_name  &&  <Badge className="ml-1" color="blue" size={40}>{patientStatusInfo?.location_group?.group_name}</Badge>}
                   </div>
                  </section>
                  <section className='flex flex-row'>
                    <MdArchive
                      onClick={archiveChat}
                      className={`text-everglade-600 cursor-pointer ${
                        !showArchive && 'hidden'
                      }`}
                      size={25}
                    />
                    <MdUnarchive
                      onClick={unArchiveChat}
                      className={`text-everglade-600 cursor-pointer ${
                        !showUnArchive && 'hidden'
                      }`}
                      size={25}
                    />
                    <MdOutlineAddIcCall
                      onClick={initializeCall}
                      className={`text-everglade-600 cursor-pointer ${
                        !showCallIcon && 'hidden'
                      }`}
                      size={25}
                    />
                  </section>
                </section>
              )}
            />
          </div>
        </>
      )}
      {message && !messageId && (
        <Modal
          title={'Forward Message'}
          close={() => {
            setMessage('')
            setIsTeam(false)
          }}
        >
          <ForwardMessageModal
            channel={channel}
            forwardedFrom={messageForwardedFrom}
            message={message}
            isTeam={isTeam}
            setIsTeam={setIsTeam}
            close={() => {
              setMessage('')
              setMessageForwardedFrom('')
              setIsTeam(false)
            }}
            unClose={close}
            data={PatientListinfo}
            id={forwardId}
            store={store}
            patientList={patientList}
            filteredChannels={filteredChannels}
          />
        </Modal>
      )}
      {/* {messageId && message && <Modal close={()=> { setMessageId('');setMessage('') }} > 
        <UpdateMessage channel={channel} message={message} messageId={messageId} close={()=>{ setMessageId('');setMessage('') }}  sendBirdSelectors={sendBirdSelectors} store={store}/>
         </Modal> } */}
    </>
  )
}
export default ChatUI
