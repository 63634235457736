export const SENDBIRD_INFO = {
  appId:'18C5DFF5-E69F-4397-82DF-3DD9EC8BF18E', //prod,
  sendBirdToken:'f235fc53bd95780fd9f73ba812b1df980984ec2f' //prod
  // appId : 'F12F8D37-6C2B-4998-B786-549F23E2F58C',//local
  // sendBirdToken:'276551bea1028547e207b12d2c07aa757053a8b5'//local
  };


// const one = {
//   name:"Hello Test",
//   id:"PA16"
// }  
// const two = {
//   name:"jeremy",
//   id:"PR1"
// }  
// const thre = {
//   name:"first users",
//   id:"PA10828"
// }  
// const four = {
//   name:"Anastasia Benson, D.O",
//   id:"PR11"
// }  
// const five = {
//   name:"Annette Marin",
//   id:"PR10"
// } 
//  const six = {
//   name:"mellisa",
//   id:"PR3"
// }  
// const seven ={
//   name:"nikolas",
//   id:"PA14"
// }
 