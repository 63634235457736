import { Icon } from '@iconify/react'
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext'
import { useEffect, useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { getMessageCount } from '../../../redux/slices/notifications'
import { toogle } from '../../../redux/slices/sidebar'
import { Menu } from '@headlessui/react'
import Avatar from '../chat/avatar'
import { useLocation, useNavigate } from 'react-router-dom'
import { getdoctorinfo } from '../../../redux/actions/passActions'
import { DoctorDetailsRequest } from '../../../redux/actions/authActions'
import { logout } from '../../../redux/slices/authenticate'
const Navbar = () => {
  const store = useSendbirdStateContext()
  var sdk = store?.stores.sdkStore.sdk
  const [total, setTotal] = useState(0)
  const [firstName,setFirstName]=useState('')
  const [secondName,setSecondName]=useState('')
  const { messagesCount } = useSelector((state) => state.notification)
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  
  const handleSearchButtonClick = () => {
    const option = {
      state:{"search_key": searchQuery}
    };
    if(searchQuery){
    navigate('/patientList',option);
    setSearchQuery('');
    }
  };
  const dispatch = useDispatch()
  const handleToggle = () => {
    dispatch(toogle())
  }

  const { docinfo } = useSelector(
    (state) => state.pass
  )

  const { userToken, userInfo, doctorDetails } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    dispatch(getdoctorinfo({ token: userToken, docid: userInfo.id }))
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(DoctorDetailsRequest({ id: userInfo?.id }))
    //eslint-disable-next-line
  }, [])

  const navigate = useNavigate()

  const fetchChannels = async () => {
    let sendbird = sdk
    let query = sendbird.groupChannel.createMyGroupChannelListQuery({
      limit: 10,
      // customTypesFilter: [],
      //includeEmpty: true,
      order: 'latest_last_message',
    })
    const fetchedChannels = await query.next()
    dispatch(getMessageCount(fetchedChannels))
  }
  const handleLogoutClick = () => {
    localStorage.clear()
    navigate('/login')
    dispatch(logout());
  };
  
  useEffect(() => {
    if (!sdk || !sdk.groupChannel) {
      return
    }
    fetchChannels()
    //eslint-disable-next-line
  }, [sdk])

  useEffect(() => {
    const sumOfUnread = messagesCount.reduce(
      (sum, message) => sum + message.unreadMessageCount,
      0
    )
    setTotal(sumOfUnread)
  }, [messagesCount])

  useEffect(()=>{
    if(docinfo){
      const [firstLetter, secondletter] = docinfo?.name?.split(" ").map((part) => part.charAt(0).toUpperCase());
      setFirstName(firstLetter)
      setSecondName(secondletter)
    }
  },[docinfo])

  const goToMessagesPage =()=>{
    navigate('/chat')
  }

  return (
    <div className='w-full h-16 flex items-center px-5 relative  bg-everglade-500 z-40 shadow-sm'>
     <section style={{"marginRight":"30px"}}>
       <AiOutlineMenu
        size={24}
        className='cursor-pointer text-white'
        onClick={handleToggle}
      />
      </section>
      <section className='w-6/12 '>
       {(location.pathname ==='/patientList' || location.pathname.includes("/patientDetails")) ? null : <div className="relative">
          <input
            type="text"
            placeholder="Search Patient ..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="w-full py-2 px-2 rounded-lg border border-gray-300 pr-24 focus:border-everglade-500 focus:outline-none focus:ring-everglade-500"
          />
          <button
            onClick={handleSearchButtonClick}
            className="absolute top-1/2 right-[0.1rem] transform translate-y-[-50%] p-2 bg-everglade-800 rounded-lg hover:bg-everglade-500"
          >
            <Icon icon={'icon-park-outline:find'} className="text-white" />
          </button>
        </div>
      }
      </section>
      <section className='w-full  cursor-pointer flex-1 flex justify-end'>
        <div className='flex justify-between items-center  p-5 mt-2'>
          <div className=' flex items-center justify-center border-spacing-3 pr-3'>
            <Icon
              icon='uiw:message'
              width='25'
              height='25'
              className='text-white'
              onClick={goToMessagesPage}
            />
            {total>0 &&
            <div className='relative -top-3 right-3 w-5 h-5 bg-red-500 rounded-full flex items-center justify-center text-white text-xs font-bold'>
              {total}
            </div>}
          </div>
          <div>
          <Menu as={'div'} className={'relative inline-block text-left '}>
              <Menu.Button >
              {!(doctorDetails?.data?.profile_image)?
              <Avatar fname={firstName} lname={secondName} width={"w-9 h-9"}  />:
                <Avatar
                  image={doctorDetails?.data?.profile_image}
                  width={"w-9 h-9"}
                  profile={true}
                />}
                
              </Menu.Button>
              <Menu.Items
                className=' fixed z-10 h-fit right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
                style={{
                  zIndex: 5,
                }}
              >
                <div className="px-1 py-1 flex flex-col">
                {userInfo && userInfo.doctor_login && userInfo.doctor_login.user_type && userInfo.doctor_login.user_type.length > 0 ? null :  <Menu.Item>
                  {({ active }) => (
                    <p
                    className={`${active && 'bg-everglade-700 text-white'} p-2`}
                    onClick={()=> navigate('/profile') }
                    >
                      Profile
                    </p>
                  )}
                </Menu.Item>}
                <Menu.Item>
                  {({ active }) => (
                    <p
                      className={`${active && 'bg-everglade-700 text-white'} p-2`}
                      onClick={()=> navigate('/change-password') }
                    >
                      Change password
                    </p>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <p
                      className={`${active && 'bg-everglade-700 text-white'} p-2`}
                      onClick={handleLogoutClick }
                    >
                     Logout 
                     {/* <Icon icon='humbleicons:logout' fontSize={24}  /> */}
                    </p>
                  )}
                </Menu.Item>
                </div>
              </Menu.Items>
            </Menu>
          </div>
          
        </div>
        
      </section>
    </div>
  )
}
export default Navbar
