import { SENDBIRD_INFO } from "../helpers/constant"
import { toastError } from "../helpers/methods"

const removeDataOnAssignedChat = async (channel,cb,toast) => {
    var dataParams = { data: '' }
    var hideList = channel.members.filter((x) => !x.userId.startsWith('PA'))
    fetch(
      `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${channel.url}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Api-Token': SENDBIRD_INFO.sendBirdToken,
        },
        body: JSON.stringify(dataParams),
      }
    )
      .then((updatedChannel) => {
        if (updatedChannel) {
          Promise.all([
            hideList.map(async (x) => {
              return fetch(
                `https://api-${SENDBIRD_INFO.appId}.sendbird.com/v3/group_channels/${channel.url}/hide`,
                {
                  method: 'PUT',
                  headers: {
                    'Content-Type': 'application/json',
                    'Api-Token': SENDBIRD_INFO.sendBirdToken,
                  },
                  body: JSON.stringify({
                    user_id: x.userId,
                    allow_auto_unhide: true,
                  }),
                }
              )
            }),
          ])
        }
      })
      .then(() => {
        cb()
      }).catch((error)=>{
        toastError(error)
      })
  }

  export default  removeDataOnAssignedChat