import { Form, Formik } from "formik";
import { InputSelect, InputText,InputFloatingLabel } from "../shared/input";
import { PurposeData, RequsterData } from "../../utils/helpers/options";
import { useEffect, useState } from "react";
import { addPaymentSchema } from "./schema";
import { useDispatch, useSelector } from "react-redux";
import { addPaymentRequest, getPatientDetails } from "../../redux/actions/passActions";
import Spinner from "../shared/spinner";
import { toastModal } from "../../utils/helpers/methods";

const AddPaymentRequest = ({ patientId, close }) => {
    const selector = useSelector(state => state.auth);
    const { userToken, userInfo } = useSelector((state) => state.auth);
    const { addPaymentRequest: payRequestState } = useSelector(state => state.patient);
    const dispatch = useDispatch();
    const initialValues = {
        amount: "",
        purpose: "",
        requester: "",
        date: "",

    }
    const handleSuccessfull = () => {
        toastModal("Successfull Done");
        setTimeout(() => {
            dispatch(getPatientDetails({ token: userToken, patientId: patientId }));
            close();
        }, 100)
    }
    const handleSubmit = (values) => {
        var params = {
            'request_from': 'care_app_web',
            'requester_id': selector.userInfo.id,
            'pid': patientId,
            'payrequest_amt': values.amount,
            'pay_purpose': values.purpose,
            'pay_requester': values.requester,
            'request_datetime': values.date,
        }
        if(userInfo && userInfo.doctor_login && userInfo.doctor_login.user_type && userInfo.doctor_login.user_type.length > 0) {
            params.requester_type =  userInfo.doctor_login.user_type;
        }
        dispatch(addPaymentRequest({ data: params, success: handleSuccessfull }))
    }
    return (
        <>
           
            <div className="w-full h-min p-2">
                <section className="px-2">
                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={addPaymentSchema} >
                        <Form >
                            <section className="flex flex-col w-full">
                                {/* <InputText name="amount" label="Amount" placeholder="Amount"/> */}
                                <InputFloatingLabel name="amount" label="Amount"  />
                                <div className="space-y-5 lg:space-y-0 grid grid-cols-1 lg:grid-cols-2 items-center gap-5">
                                <InputSelect name="purpose" placeholder="Select purpose" label="Purpose" options={PurposeData} />
                                <InputSelect name="requester" placeholder="Select requester" label="Requester" options={RequsterData} />
                                <InputText name="date" type="date" label="Date"/>
                                </div>
                            </section>
                            <section className="flex justify-center items-center">
                                <button type="submit"
                                    disabled={payRequestState.loading}
                                    className="lg:flex border-2   mt-3.5 w-[153PX] h-14 lg:mt-12 rounded border-everglade-700 bg-everglade-700 hover:bg-everglade-400  hover:border-everglade-400 uppercase flex items-center justify-center text-sm poppins font-medium text-white">
                                    {payRequestState.loading ? <Spinner /> : 'Submit'}
                                </button>
                            </section>
                        </Form>
                    </Formik>
                </section>
            </div>
        </>
    );
}
export default AddPaymentRequest;