import { Icon } from '@iconify/react'
import React, { useEffect, useState } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { PatientAvailableNonAvailableRequest, getdoctorinfo } from '../redux/actions/passActions'
import CardComponent from './CardComponent'
import useSendbirdStateContext from '@sendbird/uikit-react/useSendbirdStateContext'
import {useNavigate} from 'react-router-dom'
import Avatar from '../Components/shared/chat/avatar'


const MiddleComponent = () => {
  const { userToken, userInfo ,doctorDetails} = useSelector((state) => state.auth)
  const { docinfo,loading, error } = useSelector(
    (state) => state.pass
  )

  const navigate = useNavigate()

  const store = useSendbirdStateContext()
  var sdk = store?.stores.sdkStore.sdk
  const { available_patients,unavailable_patients} = useSelector((state) => state.patient.getAvailableNonAvailable.data);
  // const { userInfos } = useSelector((state) => state)
  const [showModal, setShowModal] = useState(false)
  // const [data, setData] = useState([])
  const [inputs, setInputs] = useState({
    name: '',
    username: '',
    image: '',
    password: '',
    isPasswordVisible: false,
    credentialsInvalid: false,
  })
  const [firstName, setFirstName] = useState('')
  const [secondName, setSecondName] = useState('')
  useEffect(() => {
    if (docinfo) {
      const [firstLetter, secondletter] = docinfo?.name
        ?.split(' ')
        .map((part) => part.charAt(0).toUpperCase())
      setFirstName(firstLetter)
      setSecondName(secondletter)
    }
  }, [docinfo])

  const handleGetData = () => {
    let obj = {
      token: userToken,
      practitioner_id: userInfo.id,
      employer_filter: PatientFilterval.group,
      patient_tag: PatientFilterval.tag,
      search_key: PatientFilterval.search
    };
    dispatch(PatientAvailableNonAvailableRequest(obj))

  }

  useEffect(()=>{
    handleGetData()
    //eslint-disable-next-line
  },[])


  useEffect(()=>{
    if(sdk && sdk.updateCurrentUserInfo){
      (async()=>{
          const params = {
            nickname: docinfo?.name,
            profileUrl: docinfo?.profile_image,
          }
          const metaData ={
            patient:"false"
          }
          await sdk?.updateCurrentUserInfo(params).then((rev) => {
            console.log('updated profile ....')
          })
          await sdk.currentUser.createMetaData(metaData).then(()=>{
            console.log('updated metadata ok ....')
          }).catch(err=>console.log({err}))
      })()
    }
    return
    //eslint-disable-next-line
  },[docinfo,sdk])


  const [PatientFilterval, setPatientFilterval] = useState({
    group: '',
    tag: '',
    search: '',
  });
  const dispatch = useDispatch()
  useEffect(() => {
    //   dispatch(getListPasswords({ token: userToken }));
    dispatch(getdoctorinfo({ token: userToken, docid: userInfo.id }))
    //eslint-disable-next-line
  }, [])

  const createPassword = () => {
    dispatch(getdoctorinfo({ token: userToken, docid: userInfo.id }))
    // dispatch(registerPassword(payload));
    // dispatch(getListPasswords({ token: userToken }));
  }

  const handleNavigate =(url,id)=>{
     id?navigate(`/${url}`,{state:id}):navigate(`/${url}`)
  }

  return (
    <div className='w-full h-screen'>
      <div className='w-full flex md:flex-row flex-col justify-between'>
        <h1 className='text-[25px] font-bold text-everglade-800'>Home</h1>
      </div>
      <div className='p-3 w-[100%]'>
        <div className="flex h-100 gap-10">
          <div className="w-[60%] rounded-lg shadow-lg overflow-hidden flex flex-col">
            <div className="w-full h-64 bg-gradient-to-b to-everglade-200 from-everglade-400 flex justify-center items-center">

              {!doctorDetails?.data?.profile_image ? (
                <Avatar
                  fname={firstName}
                  lname={secondName}
                  width={'w-[150px] h-[150px] text-2xl '}
                />

              ) : (
                <Avatar
                  image={doctorDetails?.data?.profile_image}
                  width={'w-[200px] h-[200px] text-2xl '}
                  profile={true}
                />
              )}

            </div>
            <div className="flex flex-col  p-2  justify-center items-center" >
              <div className="grid grid-cols-1">
                <h1 className='text-everglade-950 font-bold tracking-wide text-xl capitalize'>
                  {doctorDetails?.data?.name}
                </h1>
              </div>
            </div>
            <div className="flex flex-col justify-between p-4">
              <div className="grid grid-cols-2 gap-0">
                <div className="font-bold text-everglade-900 p-1">
                  Practitioner ID
                </div>
                <div className=" p-1">
                  {doctorDetails?.data?.id}
                </div>
                {doctorDetails?.data?.title !==null && <div className="font-bold text-everglade-900 p-1">
                  Title
                </div>}
                {doctorDetails?.data?.title !==null &&
                <div className="p-1 break-all">
                  {doctorDetails?.data?.title}
                </div>}
                <div className="font-bold text-everglade-900 p-1">
                  Email ID
                </div>
                <div className="p-1 break-all">
                  {doctorDetails?.data?.email_address}
                </div>
                {doctorDetails?.data?.gender && <><div className="font-bold text-everglade-900 p-1">
                  Gender
                </div>
                <div className=" p-1">
                  {doctorDetails?.data?.gender}
                </div> </>}
                {doctorDetails?.data?.location?.phone_number && 
                <>
                <div className="font-bold text-everglade-900 p-1">
                  Phone
                </div>
                <div className=" p-1">
                  {doctorDetails?.data?.location?.phone_number}
                </div></>}
                {doctorDetails && doctorDetails.data && doctorDetails.data.role && doctorDetails.data.role.length > 0 && 
                <><div className="font-bold text-everglade-900 p-1">
                  Role
                </div>
                <div className=" p-1">
                  {doctorDetails.data.role.charAt(0).toUpperCase()+doctorDetails.data.role.slice(1)}
                </div>
                </>}
              </div>
            </div>
          </div>



          {available_patients && <div className="w-[60%] rounded-lg shadow-lg overflow-hidden flex flex-col" onClick={()=>handleNavigate('patientList',{"option": 1})}>
            <div className="w-full h-64 bg-gradient-to-b from-everglade-500 to-everglade-800 flex justify-center items-center">
              <div className="w-40 ">
                <CircularProgressbar
                  value={(available_patients?.length / (unavailable_patients?.length + available_patients?.length)) * 100}
                  text={`${available_patients?.length}/${unavailable_patients?.length + available_patients?.length}`}
                  className='text-white '
                  styles={buildStyles({
                    pathColor: 'white',
                    textColor: 'white',
                    trailColor: '#4f9d4a',
                  })}
                />
              </div>
            </div>
            <div className="flex flex-col  p-2  justify-center items-center" >
              <h1 className='text-everglade-950 font-bold tracking-wide text-xl capitalize'>
                Available
              </h1>
              <h1 className='text-everglade-950 font-bold tracking-wide text-xl capitalize'>
                Patient on Connect App
              </h1>

            </div>
            <div className="flex flex-col justify-between p-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="font-bold text-everglade-900 p-1">
                  No of Patient Available on connect App
                </div>
                <div className="p-1">
                  {available_patients?.length}
                </div>
                <div className="font-bold text-everglade-900 p-1">
                  Total Patients on connect App
                </div>
                <div className=" p-1">
                  {unavailable_patients?.length + available_patients?.length}
                </div>


              </div>
            </div>
          </div>}

          {unavailable_patients && <div className="w-[60%] rounded-lg shadow-lg overflow-hidden flex flex-col" onClick={()=>handleNavigate('patientList',{"option": 2})}>
            <div className="w-full h-64 bg-gradient-to-b from-everglade-500 to-everglade-800 flex justify-center items-center">
              <div className="w-40 ">
                <CircularProgressbar
                  value={(unavailable_patients?.length / (unavailable_patients?.length + available_patients?.length)) * 100}
                  text={`${unavailable_patients?.length}/${unavailable_patients?.length + available_patients?.length}`}
                  className='text-white '
                  styles={buildStyles({
                    pathColor: 'white',
                    textColor: 'white',
                    trailColor: '#4f9d4a',
                  })}
                />
              </div>
            </div>
            <div className="flex flex-col  p-2  justify-center items-center" >
              <div className="grid grid-cols-1">
                <div className="flex flex-col  p-2  justify-center items-center" >
                  <h1 className='text-everglade-950 font-bold tracking-wide text-xl capitalize'>
                    UnAvailable
                  </h1>
                  <h1 className='text-everglade-950 font-bold tracking-wide text-xl capitalize'>
                    Patient on Connect App
                  </h1>

                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between p-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="font-bold text-everglade-900 p-1">
                  No of Patient UnAvailable on connect App
                </div>
                <div className="p-1">
                  {unavailable_patients?.length}
                </div>
                <div className="font-bold text-everglade-900 p-1">
                  Total Patients on connect App
                </div>
                <div className=" p-1">
                  {unavailable_patients?.length + available_patients?.length}
                </div>


              </div>
            </div>

          </div>}
        </div>
      </div>
      {showModal && (
        <div
          className='fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full'
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
          }}
        >
          <div className='relative w-full h-full flex justify-center items-center'>
            <div className='relative w-[600px] min-h-[200px] bg-white rounded-lg shadow dark:bg-gray-700'>
              <div className='flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600'>
                <h3 className='text-xl font-semibold text-gray-900 dark:text-white'>
                  Add a Password
                </h3>
                <button
                  onClick={() => setShowModal(false)}
                  type='button'
                  className='text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
                  data-modal-hide='defaultModal'
                >
                  <svg
                    aria-hidden='true'
                    className='w-5 h-5'
                    fill='currentColor'
                    viewBox='0 0 20 20'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      fillRule='evenodd'
                      d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                      clipRule='evenodd'
                    ></path>
                  </svg>
                  <span className='sr-only'>Close modal</span>
                </button>
              </div>
              <div className='p-6 flex flex-col'>
                <label className='mt-[10px] mb-[5px]'>Place</label>
                <input
                  onChange={(e) =>
                    setInputs({ ...inputs, name: e.target.value })
                  }
                  type='text'
                  placeholder='Site name'
                  className='rounded-lg border-[1px] border-[#c4c4c4]'
                />
                <label className='mt-[10px] mb-[5px]'>Icon Link</label>
                <input
                  onChange={(e) =>
                    setInputs({ ...inputs, image: e.target.value })
                  }
                  type='text'
                  placeholder='Image Link'
                  className='rounded-lg border-[1px] border-[#c4c4c4]'
                />
                <label className='mb-[5px]'>Username</label>
                <input
                  onChange={(e) =>
                    setInputs({ ...inputs, username: e.target.value })
                  }
                  type='text'
                  autoComplete='nope'
                  placeholder='Email, phone, username'
                  className='rounded-lg border-[1px] border-[#c4c4c4]'
                />
                <div className='relative flex flex-col'>
                  <label className='mt-[10px] mb-[5px]'>Password</label>
                  <input
                    onChange={(e) =>
                      setInputs({ ...inputs, password: e.target.value })
                    }
                    autoComplete='new-password'
                    type={inputs.isPasswordVisible ? 'text' : 'password'}
                    placeholder='Password'
                    className='rounded-lg border-[1px] border-[#c4c4c4]'
                  />
                  <Icon
                    onClick={() =>
                      setInputs({
                        ...inputs,
                        isPasswordVisible: !inputs.isPasswordVisible,
                      })
                    }
                    icon={
                      inputs.isPasswordVisible
                        ? 'mdi:eye-outline'
                        : 'mdi:eye-off-outline'
                    }
                    color='#c4c4c4'
                    fontSize={35}
                    className='absolute top-[43px] right-[10px] cursor-pointer'
                  />
                </div>
              </div>
              <div className='flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600'>
                <button
                  onClick={createPassword}
                  data-modal-hide='defaultModal'
                  type='button'
                  className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'
                >
                  {loading ? 'Loading...' : 'Create'}
                </button>
                <button
                  onClick={() => setShowModal(false)}
                  data-modal-hide='defaultModal'
                  type='button'
                  className='text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600'
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
          {inputs.credentialsInvalid ? (
            <div
              id='alert-border-2'
              className='absolute top-2 left-0 flex p-4 mb-4 border-t-4 w-[600px] text-red-400 bg-gray-800 border-red-800'
              role='alert'
            >
              <svg
                className='flex-shrink-0 w-5 h-5'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <div className='ml-3 text-sm font-medium'>
                {error?.slice(0, 1)?.toUpperCase() + error?.slice(1)}
              </div>
              <button
                onClick={() => {
                  setInputs({ ...inputs, credentialsInvalid: false })
                }}
                type='button'
                className='ml-auto -mx-1.5 -my-1.5rounded-lg focus:ring-2 focus:ring-red-400 p-1.5  inline-flex h-8 w-8 bg-gray-800 text-red-400 hover:bg-gray-700'
                data-dismiss-target='#alert-border-2'
                aria-label='Close'
              >
                <span className='sr-only'>Dismiss</span>
                <svg
                  aria-hidden='true'
                  className='w-5 h-5'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
                    clipRule='evenodd'
                  ></path>
                </svg>
              </button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  )
}

export default MiddleComponent
